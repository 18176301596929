import React, { useState } from 'react';
import logo from '../../images/banner.svg';
import site_icon from '../../images/site-icon.png';

import SearchInputAndButtons from '../../components/SearchInputAndButtons/SearchInputAndButtons';
import ContactDialog from '../../components/ContactDialog/ContactDialog';

import './HomePage.css';

import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function HomePage({ env, urls }) {
    const [openContactDialog, setOpenContactDialog] = useState(false);
    
    const handleContactDialog = () => {
        setOpenContactDialog(true);
    };

    const handleCloseContactDialog = () => {
        setOpenContactDialog(false);
    };

    return (
        <div className="App">
            <section className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <span className="Heading1">
                    <span>Descubra quais distribuidoras de energia atuam no seu endereço</span>
                </span>

                <SearchInputAndButtons env={env} urls={urls} />
            </section>

            <section className="Home-faq">
                <span className="Heading2">
                    <span>Perguntas frequentes</span>
                </span>
                <Stack 
                    spacing={1} 
                    style={{ alignItems: "center" }}
                    divider={<Divider orientation="horizontal" flexItem style={{ alignSelf: "center", width: "80%" }} />}
                >
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            O que é Energinfo?
                        </AccordionSummary>
                        <AccordionDetails>
                            É uma ferramenta para visualização das informações públicas disponibilizadas pela ANEEL. 
                            Queremos ampliar as funcionalidades! Nos ajude enviando suas sugestões e solicitações <Box style={{ cursor: "pointer", textDecoration: "underline" }} component="span" onClick={handleContactDialog}>aqui</Box>.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            O que é Elevential?
                        </AccordionSummary>
                        <AccordionDetails>
                            É a empresa por trás do desenvolvimento do Energinfo. Temos ampla experiência em desenvolvimento de software com soluções 
                            complexas e alocação de mão de obra em TI, inclusive para a indústria de energia. Entre em contato conosco: <a href="mailto:contato@elevential.com">contato@elevential.com</a>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            Como faço para encontrar as distribuidoras de energia que atuam na minha região?
                        </AccordionSummary>
                        <AccordionDetails>
                            Basta preencher o formulário acima com o seu endereço e clicar em "Pesquisar", 
                            ou clicar em "Usar GPS" para utilizar a sua localização atual. As distribuidoras de 
                            energia que atuam na sua região serão exibidas no mapa, junto com informações sobre cada uma delas e 
                            a rede de tensão escolhida da subestação mais próxima. Ao clicar com o botão direito do mouse sobre o mapa
                            em um ponto específico, você realizará uma segunda busca para visualizar as informações da distribuidora de 
                            energia que atua naquela região.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            As distribuidoras de energia exibidas no mapa são as únicas que atuam na minha região?
                        </AccordionSummary>
                        <AccordionDetails>
                            A lista que fornecemos é baseada nas informações disponíveis para nós pelo site da ANEEL. 
                            Pode haver outras empresas operando em sua região que não estão incluídas em nossa lista.
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            Posso trocar de distribuidora de energia através deste site?
                        </AccordionSummary>
                        <AccordionDetails>
                            Não, nosso serviço é apenas para fornecer informações sobre as distribuidoras de energia 
                            que atuam na sua região. Para mais informações sobre como trocar de distribuidora, entre em contato com a ANEEL. 
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            O quão frequente é a atualização das informações sobre as distribuidoras de energia?
                        </AccordionSummary>
                        <AccordionDetails>
                            As informações sobre as distribuidoras de energia são atualizadas mensalmente, que no momento é adequado com as atualizações disponibilizadas pela ANEEL, mas podem não refletir mudanças recentes. 
                            Recomendamos que você entre em contato com a distribuidora de energia para obter informações atualizadas. 
                        </AccordionDetails>
                    </Accordion>
                    <Accordion className='Accordion-main-page'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                            Há algum custo para usar este serviço?
                        </AccordionSummary>
                        <AccordionDetails>
                            Não, nosso serviço é gratuito e não requer nenhum tipo de pagamento.
                        </AccordionDetails>
                    </Accordion>
                </Stack>
            </section>

            <section className="Home-footer">
                <Stack className="Home-footer1 Footer-container">
                    <div className="Home-container6">
                        <img
                        alt="pastedImage"
                        src={logo}
                        className="Home-pasted-image1"
                        />
                    </div>
                    <div className="Home-separator"></div>
                    <Stack direction={{ xs: 'column', sm: 'row'}} className="Home-container7">
                        <span className="Home-text38">
                            ® Elevential – Todos os direitos reservados.
                        </span>
                        <div className="Home-icon-group1">
                            <a
                                href="https://www.linkedin.com/company/elevential-it-consulting/about/"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="Home-link"
                            >
                                <img
                                    id="linkedin"
                                    alt="icon"
                                    src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/fb301b4c-62ff-4750-9ba7-9a9ba0cb0473/b04ffaa8-6729-4f4b-9a6c-e162f9656c9b?org_if_sml=13040&amp;force_format=original"
                                    viewBox="0 0 602.2582857142856 1024"
                                    className="Home-pasted-image2 Social-icons"
                                />
                            </a>
                            <a
                                href="https://elevential.com/"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="Home-link"
                            >
                                <img
                                    id="site"
                                    alt="icon"
                                    src={site_icon}
                                    viewBox="0 0 602.2582857142856 1024"
                                    className="Home-pasted-image2 Social-icons"
                                />
                            </a>
                        </div>
                    </Stack>
                </Stack>
            </section>
            <ContactDialog open={openContactDialog} onClose={handleCloseContactDialog} />
        </div>
    );
}

export default HomePage;
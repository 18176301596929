import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../images/banner.svg";
import {
    Grid,
    OutlinedInput,
    Stack,
    InputAdornment,
    IconButton,
    Box,
    Divider,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import MyLocationIcon from '@mui/icons-material/MyLocation';

import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    GeoJSON,
    AttributionControl,
    LayersControl,
} from "react-leaflet";

import L from "leaflet";

import BarChart from "../../components/BarChart/BarChart";
import ContactDialog from "../../components/ContactDialog/ContactDialog";

import "./MapPage.css";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useMapEvent } from "react-leaflet";


function SetViewOnClick({ setLatLng, openLocationDialog }) {
    useMapEvent('contextmenu', (e) => {
        setLatLng(e.latlng);
        openLocationDialog(); // Abre o diálogo
    });

    return null
}


function MapPage(props) {
    const urls = props.urls;
    const env = props.env;

    const location = useLocation();
    const [mapData, setMapData] = useState(location.state.mapData);
    const [body, setBody] = useState(JSON.parse(mapData.body));
    const [inputValue, setInputValue] = useState(location.state.endereco);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [rede, setRede] = useState(location.state.rede);
    const [redeNova, setRedeNova] = useState(rede);
    const [expandedAccordions, setExpandedAccordions] = useState({});
    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    const [latlng, setLatLng] = useState({});
    const [openContactDialog, setOpenContactDialog] = useState(false);

    const [nome_rede, setNomeRede] = useState(
        rede === "ssdbt"
            ? "Baixa Tensão"
            : rede === "ssdmt"
                ? "Média Tensão"
                : "Alta Tensão"
    );

    useEffect(() => {
        setRedeNova(rede);
    }, [rede]);

    const handleToggleAccordion = (index) => {
        setExpandedAccordions((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleCloseAllAccordions = () => {
        setExpandedAccordions({});
    };

    const isMobile = window.innerWidth <= 992;

    const handleRadioChangeInput = (event) => {
        setRede(event.target.value);
        setNomeRede(
            event.target.value === "ssdbt"
                ? "Baixa Tensão"
                : event.target.value === "ssdmt"
                    ? "Média Tensão"
                    : "Alta Tensão"
        );
    };

    const handleRadioChange = (event) => {
        setRedeNova(event.target.value);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleClearInput = () => {
        setInputValue("");
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            searchMap();
        }
    };

    const handleOpenLocationDialog = () => {
        setOpenLocationDialog(true);
    };

    const handleCloseLocationDialog = () => {
        setOpenLocationDialog(false);

        setRedeNova(rede);
    };

    const handleContactDialog = () => {
        setOpenContactDialog(true);
    };

    const handleCloseContactDialog = () => {
        setOpenContactDialog(false);
    };

    const searchMapGPS = () => {
        // TODO: Talvez seja melhor mudar para o jeito implementado em https://leafletjs.com/examples/mobile/
        setLoading(true);

        handleCloseAllAccordions();

        // Verifica se o navegador suporta geolocalização
        if (!navigator.geolocation) {
            // Exibe a mensagem de erro no elemento messageDisplay
            setError("Geolocalização não suportada pelo navegador.");

            setTimeout(setLoading(false), 500);

            return;
        }

        // Solicita a posição do usuário
        navigator.geolocation.getCurrentPosition(position => {
            // Envia a solicitação para obter o mapa
            fetch(`${urls[env]}/busca?lat=${position.coords.latitude}&lon=${position.coords.longitude}&rede=${rede}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Erro na solicitação: ${response.statusText}`);
                    }
                    return response.text();
                })
                .then(data => {
                    var mapData = JSON.parse(data);
                    var body = JSON.parse(mapData.body);

                    if ("distribuidoras" in body && body.distribuidoras.length > 0) {
                        for (let i = 0; i < body.distribuidoras.length; i++) {
                            body.distribuidoras[i].cor = pickRandomColor();
                        }

                        setError(null);
                    }
                    else {
                        setError("Nenhuma distribuidora encontrada.");
                    }

                    setBody(body);

                    setMapData(mapData);
                })
                .catch(error => {
                    // Exibe a mensagem de erro no elemento messageDisplay
                    setError("Erro ao buscar mapa");

                    console.error("Erro ao buscar mapa:", error);
                })
                .finally(() => {
                    setInputValue("");

                    setTimeout(setLoading(false), 500);
                });
        }, error => {
            // Exibe a mensagem de erro no elemento messageDisplay
            setError(`Erro ao obter a localização: ${error.message}`);
            console.error("Erro ao obter a localização:", error);

            setTimeout(setLoading(false), 500);
        });
    };

    const searchMap = () => {
        setLoading(true);

        handleCloseAllAccordions();

        // Obtem o valor do campo de endereço
        var endereco = inputValue;

        console.log("Endereço:", endereco);

        // Verifica se o campo de endereço está vazio
        if (!endereco) {
            // Exibe a mensagem de erro no elemento messageDisplay
            setError("O campo de endereço não pode estar vazio.");

            setTimeout(setLoading(false), 500);

            return;
        }

        // Codifica o endereço para ser enviado na URL
        var enderecoCodificado = encodeURIComponent(endereco);

        // Envia a solicitação para obter o mapa
        fetch(`${urls[env]}/busca?endereco=${enderecoCodificado}&rede=${rede}`)
            .then(response => {
                if (!response.ok) {
                    // Se a resposta não for OK, lança um erro
                    throw new Error(`Erro na solicitação: ${response.statusText}`);
                }

                // Retorna o statusText
                return response.text();
            })
            .then(data => {
                var mapData = JSON.parse(data);

                var body = JSON.parse(mapData.body);

                if ("distribuidoras" in body && body.distribuidoras.length > 0) {
                    for (let i = 0; i < body.distribuidoras.length; i++) {
                        body.distribuidoras[i].cor = pickRandomColor();
                    }

                    setError(null);
                }
                else {
                    setError("Nenhuma distribuidora encontrada.");
                }

                setBody(body);

                setMapData(mapData);
            })
            .catch(error => {
                // Exibe a mensagem de erro no elemento messageDisplay
                setError("Erro ao buscar mapa");

                console.error("Erro ao buscar mapa:", error);
            })
            .finally(() => {
                setTimeout(setLoading(false), 500);
            });
    };

    const searchPosition = (lat, lon) => {
        handleRadioChangeInput({ target: { value: redeNova } });

        handleCloseLocationDialog();

        setLoading(true);

        handleCloseAllAccordions();

        // Envia a solicitação para obter o mapa
        fetch(`${urls[env]}/busca?lat=${lat}&lon=${lon}&rede=${redeNova}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Erro na solicitação: ${response.statusText}`);
                }
                return response.text();
            })
            .then(data => {
                var mapData = JSON.parse(data);
                var body = JSON.parse(mapData.body);

                if ("distribuidoras" in body && body.distribuidoras.length > 0) {
                    for (let i = 0; i < body.distribuidoras.length; i++) {
                        body.distribuidoras[i].cor = pickRandomColor();
                    }

                    setError(null);
                }
                else {
                    setError("Nenhuma distribuidora encontrada.");
                }

                setBody(body);

                setMapData(mapData);
            })
            .catch(error => {
                // Exibe a mensagem de erro no elemento messageDisplay
                setError("Erro ao buscar mapa");

                console.error("Erro ao buscar mapa:", error);
            })
            .finally(() => {
                setInputValue("");

                setTimeout(setLoading(false), 500);
            });
    };

    const pickRandomColor = () => {
        const colors = [
            "#FF6633", "#004D66", "#7EBAB5", "#4682B4",
            "#B8860B", "#006400", "#A07AFF", "#696969",
            "#8B0000", "#556B2F", "#046904", "#800080",
            "#FF1493", "#FF4500", "#2E8B57", "#008000",
            "#4169E1", "#2F4F4F", "#8A2BE2", "#CC2C24",
            "#008080", "#808080",           
        ];

        return colors[Math.floor(Math.random() * colors.length)];
    };

    return (
        <Grid container>
            <Grid item xs={12} md={4}>
                <Stack className="Map-side-info" spacing={2}>
                    <a href="/">
                        <img src={logo} className="App-logo" alt="logo" />
                    </a>
                    <OutlinedInput
                        className="Map-address-input"
                        id="address"
                        placeholder="Rua Principal 123, Cidade, Estado"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        endAdornment={
                            <InputAdornment position="end">
                                {
                                    inputValue && <IconButton edge="end" onClick={handleClearInput}>
                                        <ClearIcon />
                                    </IconButton>
                                }
                                <IconButton edge="end" onClick={() => { searchMapGPS() }}>
                                    <MyLocationIcon />
                                </IconButton>
                                <IconButton edge="end" onClick={() => { searchMap() }}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormControl>
                        <RadioGroup
                            className="Map-rede-radio-group"
                            row
                            value={rede}
                            onChange={handleRadioChangeInput}
                        >
                            <FormControlLabel value="ssdbt" control={<Radio size="small" />} label="Baixa Tensão" />
                            <FormControlLabel value="ssdmt" control={<Radio size="small" />} label="Média Tensão" />
                            <FormControlLabel value="ssdat" control={<Radio size="small" />} label="Alta Tensão" />
                        </RadioGroup>
                    </FormControl>
                    {loading && <LoadingSpinner loading={loading} />}
                    {error && <div className='Error-overlay'>
                        <span>{error}</span>
                    </div>}
                    {body.distribuidoras ? (
                        <Stack spacing={2} style={{ width: "100%" }}>
                            <span className="Heading2" style={{ textAlign: "start" }}>
                                <span>Distribuidoras no seu endereço</span>
                            </span>
                            <div className="Cards-section">
                            {body.distribuidoras.map((distribuidora, index) => (
                                <Box 
                                    key={distribuidora.nome + index} 
                                    className="Distribuidora-info"
                                    onClick={handleToggleAccordion.bind(this, index)}
                                >
                                    <Stack>
                                        <Stack direction={"row"}>
                                            <Box className="Distribuidora-info-band" style={{ backgroundColor: distribuidora.cor }}></Box>
                                            <Box className="Distribuidora-info-content">
                                                <h3>{distribuidora.nome}</h3>
                                                <div>
                                                    <b>Tipo de Contrato:</b> {distribuidora.tipo_contrato}
                                                    <br></br><b>Vigente:</b> {distribuidora.vigente}
                                                    <br></br><b>Área:</b> {distribuidora.area} km²
                                                    <br></br><b>Data da última atualização:</b> {distribuidora.data_arquivo}
                                                </div>
                                            </Box>
                                            <ExpandMore className="Distribuidora-info-expand-icon" />
                                        </Stack>
                                        <Collapse in={expandedAccordions[index]} timeout="auto" unmountOnExit>
                                            <Box className="Distribuidora-info-accordion-details">
                                                {distribuidora.energias[0] && (
                                                    <BarChart 
                                                        data={distribuidora.energias} 
                                                        color={distribuidora.cor} 
                                                        label={`${distribuidora.num_consumidores} consumidores ${nome_rede}`}
                                                        title={`Rede da subestação mais próxima (Subestação ${distribuidora.nome_subestacao})`} 
                                                    />
                                                )}
                                                {!distribuidora.energias[0] && (
                                                    <Box style={{ display: "flex", justifyContent: "center", color: "black"}}>
                                                        <span>Não há dados de consumo disponíveis.</span>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Collapse>
                                    </Stack>
                                </Box>
                            ))}
                            </div>
                        </Stack>
                    ) : (<div></div>)}
                    <Stack spacing={2} className="Footer" >
                        <Box className="Contact-modal-box" onClick={handleContactDialog}>
                            <div>
                                Não encontrou o que estava procurando? Tem alguma sugestão? Clique aqui e nos envie uma mensagem!
                            </div>
                        </Box>
                        <Divider orientation="horizontal" flexItem style={{ alignSelf: "center", width: "100%" }} />
                        <span style={{ textAlign: "center", fontSize: "1rem", color: "gray" }}>
                            ® Elevential – Todos os direitos reservados.
                        </span>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
                <div className="MapPage">
                    <MapContainer
                        key={body.lat + body.lon}
                        center={[body.lat, body.lon]}
                        zoom={12}
                        style={{ height: "100vh", width: "100%" }}
                        prefix="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attributionControl={false}
                        scrollWheelZoom={!isMobile}
                    >
                        <AttributionControl position="bottomright" prefix={false} />
                        <Marker position={[body.lat, body.lon]}></Marker>

                        {/* Percorre as distribuidoras e coloca na tela as áreas de atuação e segmentos de rede */}
                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="OpenStreetMap">
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='<a href="https://leafletjs.com/" target="_blank" rel="noopener noreferrer">Leaflet</a> | &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>'
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Satélite">
                                <TileLayer
                                    url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                    attribution='<a href="https://leafletjs.com/" target="_blank" rel="noopener noreferrer">Leaflet</a> | &copy; <a href="https://www.google.com/maps">Google Maps</a>'
                                />
                            </LayersControl.BaseLayer>
                        {body.distribuidoras ? (
                            body.distribuidoras.map((distribuidora, index) => {
                                return (
                                    <>
                                        <LayersControl.Overlay 
                                            key={"overlay" + distribuidora.nome + index} 
                                            name={"ARAT da " + distribuidora.nome} checked
                                        >
                                            <GeoJSON
                                                key={JSON.stringify(distribuidora.geometria_shape)}
                                                data={distribuidora.geometria_shape}
                                                style={() => ({
                                                    color: distribuidora.cor,
                                                    weight: 2,
                                                    opacity: 0.65,
                                                })}
                                            >
                                                <Popup key={distribuidora.nome + index + "popup"}>
                                                    <div>
                                                        <h3>{distribuidora.nome}</h3>
                                                        <div>
                                                            <b>Tipo de Contrato:</b> {distribuidora.tipo_contrato}
                                                            <br></br><b>Vigente:</b> {distribuidora.vigente}
                                                            <br></br><b>Área:</b> {distribuidora.area} km²
                                                            <br></br><b>Data da última atualização:</b> {distribuidora.data_arquivo}
                                                            <br></br><b>Segmento de rede mais próximo:</b> {distribuidora.distancia} m
                                                            <br></br><b>Potência nominal da rede mais próxima:</b> {distribuidora.potencia} kVA
                                                        </div>
                                                    </div>
                                                </Popup>
                                            </GeoJSON>
                                        </LayersControl.Overlay>
                                        <LayersControl.Overlay 
                                            key={"Subestação" + distribuidora.nome + index} 
                                            name={"Subestação mais próxima da " + distribuidora.nome}
                                            checked
                                        >
                                            {distribuidora.subestacao_shape ? (
                                                <GeoJSON
                                                    key={JSON.stringify(distribuidora.subestacao_shape)}
                                                    data={distribuidora.subestacao_shape}
                                                    style={() => ({
                                                        color: distribuidora.cor,
                                                        weight: 2,
                                                        opacity: 0.85,
                                                    })}
                                                >
                                                </GeoJSON>
                                            ) : (<div></div>)}
                                        </LayersControl.Overlay>
                                        <LayersControl.Overlay 
                                            key={"rede" + JSON.stringify(distribuidora.segmento_rede_shape)} 
                                            name={"Rede da " + distribuidora.nome}
                                        >
                                            {distribuidora.segmento_rede_shape ? (
                                                <GeoJSON
                                                    key={JSON.stringify(distribuidora.segmento_rede_shape)}
                                                    data={distribuidora.segmento_rede_shape}
                                                    style={() => ({
                                                        color: distribuidora.cor,
                                                        weight: 2,
                                                        opacity: 0.65,
                                                    })}
                                                >
                                                </GeoJSON>
                                            ) : (<div></div>)}
                                        </LayersControl.Overlay>
                                        <LayersControl.Overlay 
                                            key={"consumidores" + distribuidora.nome + index} 
                                            name={"Consumidores da " + distribuidora.nome}
                                        >
                                            {distribuidora.consumidores_shape ? (
                                                <GeoJSON
                                                    key={JSON.stringify(distribuidora.consumidores_shape)}
                                                    data={distribuidora.consumidores_shape}
                                                    pointToLayer={(_feature, latlng) => {
                                                        return L.circleMarker(latlng, {
                                                            radius: 5,
                                                            fillColor: distribuidora.cor,
                                                            color: "#000",
                                                            weight: 1,
                                                            opacity: 1,
                                                            fillOpacity: 0.8,
                                                        });
                                                    }}
                                                >
                                                </GeoJSON>
                                            ) : (<div></div>)}
                                        </LayersControl.Overlay>
                                    </>
                                );
                            })
                        ) : (<div></div>)}
                        </LayersControl>
                        <SetViewOnClick openLocationDialog={handleOpenLocationDialog} setLatLng={setLatLng} />
                    </MapContainer>
                </div>
            </Grid>
            {isMobile && <ScrollToTopButton />}
            <Dialog open={openLocationDialog} onClose={handleCloseLocationDialog}>
                <DialogTitle fontWeight="bold">Deseja pesquisar as distribuidoras que atuam neste local?</DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" justifyContent="center">
                        <FormControl>
                            <RadioGroup
                                className="Map-rede-radio-group"
                                row
                                value={redeNova}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="ssdbt" control={<Radio size="small" />} label="Baixa Tensão" />
                                <FormControlLabel value="ssdmt" control={<Radio size="small" />} label="Média Tensão" />
                                <FormControlLabel value="ssdat" control={<Radio size="small" />} label="Alta Tensão" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Stack direction="row" spacing={2} display="flex" justifyContent="flex-end">
                        <IconButton size="small" variant="contained" className="Dialog-search-button" onClick={() => searchPosition(latlng.lat, latlng.lng)}>Pesquisar</IconButton>
                        <IconButton size="small" className="Dialog-cancel-button" onClick={handleCloseLocationDialog}>Cancelar</IconButton>
                    </Stack>
                </DialogContent>
            </Dialog>
            <ContactDialog open={openContactDialog} onClose={handleCloseContactDialog} url={urls[env]} />
        </Grid>
    );
}

export default MapPage;
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const BarChart = ({ data, color, label, title }) => {
    const chartData = {
        labels: Array.from({ length: data.length }, (_, i) => `${i + 1}º`),
        datasets: [
            {
                label: "Energia medida (kWh)",
                backgroundColor: color,
                borderColor: "rgba(0,0,0,1)",
                borderWidth: 2,
                data: data,
            },
        ],
    };

    return (
        <>
            <h4 style={{ textAlign: "center", margin: "0px" }}>{title}</h4>
            <h5 style={{ textAlign: "center", marginTop: "0px" }}>{label}</h5>
            <Bar
                data={chartData}
                options={{
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    },
                }}
            />
        </>
    );
};

export default BarChart;
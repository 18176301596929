import React, { useState } from 'react';

import { 
    Dialog, 
    DialogContent,
    DialogTitle,
    TextField,
    Stack,
    Button,
    Snackbar
} from '@mui/material';

import ReCAPTCHA from 'react-google-recaptcha';


function ContactDialog({ open, onClose, url }) {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [nomeError, setNomeError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mensagemError, setMensagemError] = useState('');
    const [enviado, setEnviado] = useState(false);
    const [error, setError] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);

    const enviarEmail = () => {
        if (!nome || !email || !mensagem || !captchaValue) {
            if (!nome) setNomeError('Campo obrigatório');
            if (!email) setEmailError('Campo obrigatório');
            if (!mensagem) setMensagemError('Campo obrigatório');
            if (!captchaValue) setCaptchaValue('Por favor, preencha o reCAPTCHA');

            return;
        }

        const body = {
            to: "sugestao@energinfo.com.br",
            subject: "Sugestão - EnergInfo",
            from: "sugestao@energinfo.com.br",
            content: `Nome: ${nome}\nEmail: ${email}\nMensagem: ${mensagem}`,
            replyTo: email,
            captcha: captchaValue
        };

        fetch(`${url}/email`, {
            method: "POST",
            body: JSON.stringify(body),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Erro ao enviar o email");
            }

            console.log("Email enviado com sucesso!", response);

            setEnviado(true);

            // Limpar os campos após o envio bem-sucedido
            setNome('');
            setEmail('');
            setMensagem('');
            setCaptchaValue(null);

            // Fechar o diálogo após o envio bem-sucedido
            setTimeout(() => onClose(), 1500);
        })
        .catch(error => {
            console.error("Erro ao enviar o email:", error);

            setError(true);
        });
    };

    const handleNomeChange = (e) => {
        setNome(e.target.value);
        setNomeError('');
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handleMensagemChange = (e) => {
        setMensagem(e.target.value);
        setMensagemError('');
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle fontWeight="bold">
                Nos envie sua sugestão!
            </DialogTitle>
            <DialogContent>
                {enviado && ( // Mostra a mensagem apenas se o e-mail for enviado com sucesso
                    <Snackbar
                        open={enviado}
                        autoHideDuration={6000}
                        onClose={() => setEnviado(false)}
                        message="E-mail enviado com sucesso!"
                    />
                )}
                {error && ( // Mostra a mensagem apenas se houver um erro ao enviar o e-mail
                    <Snackbar
                        open={!!error}
                        autoHideDuration={6000}
                        onClose={() => setError('')}
                        message="Erro ao enviar o email. Tente novamente mais tarde."
                    />
                )}
                <TextField 
                    fullWidth
                    label="Nome"
                    variant="outlined"
                    value={nome}
                    onChange={handleNomeChange}
                    error={!!nomeError}
                    helperText={nomeError}
                    required
                />
                <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                    required
                />
                <TextField
                    fullWidth
                    label="Sugestão"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={mensagem}
                    onChange={handleMensagemChange}
                    error={!!mensagemError}
                    helperText={mensagemError}
                    required
                />
                <ReCAPTCHA
                    style={{ marginTop: "0.5rem" }}
                    sitekey="6LeSn9MpAAAAAGKOulsbQZjyBhLrcP3p-jfHGNxA"
                    onChange={handleCaptchaChange}
                />
                <Stack direction="row" spacing={2} display="flex" justifyContent="flex-end" marginTop="1rem">
                    <Button 
                        variant="contained"
                        onClick={enviarEmail}
                        className="Dialog-search-button"
                        disabled={!nome || !email || !mensagem || !captchaValue}
                    >
                        Enviar
                    </Button>
                    <Button onClick={onClose} className="Dialog-cancel-button">Cancelar</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default ContactDialog;

import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import {
    OutlinedInput, 
    Button,
    Stack,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import './SearchInputAndButtons.css';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function SearchInputAndButtons({ env, urls }) {
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [rede, setRede] = useState('ssdbt');

    const handleRadioChange = (event) => {
        setRede(event.target.value);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleClearInput = () => {
        setInputValue("");
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            searchMap();
        }
    };

    const searchMapGPS = () => {
        // TODO: Talvez seja melhor mudar para o jeito implementado em https://leafletjs.com/examples/mobile/
        setLoading(true);

        // Verifica se o navegador suporta geolocalização
        if (!navigator.geolocation) {
            // Exibe a mensagem de erro no elemento messageDisplay
            setError("Geolocalização não suportada pelo navegador");

            setTimeout(setLoading(false), 500);

            return;
        }

        // Solicita a posição do usuário
        navigator.geolocation.getCurrentPosition(position => {
            // Envia a solicitação para obter o mapa
            fetch(`${urls[env]}/busca?lat=${position.coords.latitude}&lon=${position.coords.longitude}&rede=${rede}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Erro na solicitação: ${response.statusText}`);
                    }
                    return response.text();
                })
                .then(data => {
                    var mapData = JSON.parse(data);
                    var body = JSON.parse(mapData.body);

                    if ("distribuidoras" in body && body.distribuidoras.length > 0) {
                        for (let i = 0; i < body.distribuidoras.length; i++) {
                            body.distribuidoras[i].cor = pickRandomColor();
                        }

                        mapData.body = JSON.stringify(body);

                        setError(null);

                        navigate('/map', { state: { 
                            mapData: mapData,
                            env: env,
                            urls: urls,
                            rede: rede
                        } });
                    }
                    else {
                        setError("Nenhuma distribuidora encontrada");

                        setTimeout(setLoading(false), 500);
                    }
                })
                .catch(error => {
                    // Exibe a mensagem de erro no elemento messageDisplay
                    setError("Erro ao buscar mapa");

                    console.error("Erro ao buscar mapa:", error);

                    setTimeout(setLoading(false), 500);
                });
        }, error => {
            // Exibe a mensagem de erro no elemento messageDisplay
            setError(`Erro ao obter a localização: ${error.message}`);

            console.error("Erro ao obter a localização:", error);

            setTimeout(setLoading(false), 500);
        });
    };

    const searchMap = () => {
        setLoading(true);
        // console.log(window.location.origin);

        // Obtem o valor do campo de endereço
        var endereco = inputValue;

        console.log("Endereço:", endereco);

        // Verifica se o campo de endereço está vazio
        if (!endereco) {
            // Exibe a mensagem de erro no elemento messageDisplay
            setError("O campo de endereço não pode estar vazio");
            console.error("O campo de endereço não pode estar vazio");

            setTimeout(setLoading(false), 500);

            return;
        }

        // Codifica o endereço para ser enviado na URL
        var enderecoCodificado = encodeURIComponent(endereco);

        // Envia a solicitação para obter o mapa
        fetch(`${urls[env]}/busca?endereco=${enderecoCodificado}&rede=${rede}`)
            .then(response => {
                if (!response.ok) {
                    // Se a resposta não for OK, lança um erro
                    throw new Error(`Erro na solicitação: ${response.statusText}`);
                }

                // Retorna o statusText
                return response.text();
            })
            .then(data => {
                var mapData = JSON.parse(data);
                var body = JSON.parse(mapData.body);

                if ("distribuidoras" in body && body.distribuidoras.length > 0) {
                    for (let i = 0; i < body.distribuidoras.length; i++) {
                        body.distribuidoras[i].cor = pickRandomColor();
                    }

                    mapData.body = JSON.stringify(body);

                    setError(null);

                    navigate('/map', { state: { 
                        mapData: mapData,
                        env: env,
                        urls: urls,
                        rede: rede,
                        endereco: endereco
                    } });
                }
                else {
                    setError("Nenhuma distribuidora encontrada");

                    setTimeout(setLoading(false), 500);
                }
            })
            .catch(error => {
                // Exibe a mensagem de erro no elemento messageDisplay
                setError("Erro ao buscar mapa");

                console.error("Erro ao buscar mapa:", error);

                setTimeout(setLoading(false), 500);
            });
    };

    const pickRandomColor = () => {
        const colors = [
            "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6",
            "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
            "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A",
            "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC",
            "#66994D", "#B366CC", "#4D8000", "#B33300", "#CC80CC",
            "#66664D", "#991AFF", "#E666FF", "#4DB3FF", "#1AB399",
            "#E666B3", "#33991A", "#CC9999", "#B3B31A", "#00E680",
            "#4D8066", "#809980", "#E6FF80", "#1AFF33", "#999933",
            "#FF3380", "#CCCC00", "#66E64D", "#4D80CC", "#9900B3",
            "#E64D66", "#4DB380", "#FF4D4D", "#99E6E6", "#6666FF"
        ];

        return colors[Math.floor(Math.random() * colors.length)];
    };

    return (
        <section style={{ width: "100%" }}>
            <div className="Search-input-and-buttons">
                <OutlinedInput
                    className="Address-input"
                    id="address"
                    placeholder="Rua Principal 123, Cidade, Estado"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    endAdornment={
                        inputValue ? (
                            <InputAdornment position="end">
                                <IconButton edge="end" onClick={handleClearInput}>
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    }
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                />
                <FormControl>
                    {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        className="Radio-group"
                        row
                        value={rede}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel value="ssdbt" control={<Radio size="small" />} label="Baixa Tensão" />
                        <FormControlLabel value="ssdmt" control={<Radio size="small" />} label="Média Tensão" />
                        <FormControlLabel value="ssdat" control={<Radio size="small" />} label="Alta Tensão" />
                    </RadioGroup>
                </FormControl>
                {error && <div className='Error-overlay'>
                    <span>{error}</span>
                </div>}
                <Stack spacing={{ xs: 1, md: 4, lg: 6 }} direction={{ xs: 'column', md: 'row' }}>
                    <Button variant="contained" className="Search-button" onClick={searchMapGPS}>Usar minha localização</Button>
                    <Button variant="contained" className="Search-button" onClick={searchMap}>Pesquisar</Button>
                </Stack>
            </div>
            {loading && <LoadingSpinner loading={loading} />}
        </section>
    );
}

export default SearchInputAndButtons;
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";


function LoadingSpinner({ loading }) {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (loading) {
                setShowTimeoutMessage(true);
            }
        }, 3000);

        const intervalId = setInterval(() => {
            setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [loading]);

    // Reinicia o temporizador quando o estado de loading muda para false
    useEffect(() => {
        if (!loading) {
            setElapsedTime(0);
            setShowTimeoutMessage(false);
        }
    }, [loading]);

    let timeoutMessage = "";

    if (showTimeoutMessage) {
        if (elapsedTime < 10) {
            timeoutMessage = "Buscando os dados da distribuidora.";
        } else if (elapsedTime < 14) {
            timeoutMessage = "Buscando os segmentos da rede.";
        } else if (elapsedTime < 18) {
            timeoutMessage = "Buscando a subestação.";
        } else {
            timeoutMessage = "Buscando os consumidores.";
        }
    }

    return (
        <div className="Spinner-overlay">
            <CircularProgress size={24} />
            {showTimeoutMessage && <span className="Timeout-message">{timeoutMessage}</span>}
        </div>
    );
}

export default LoadingSpinner;
import React, { useState, useEffect } from 'react';
import './ScrollToTopButton.css';

import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Adiciona um listener para verificar a posição do scroll
        window.addEventListener('scroll', toggleVisibility);

        // Remove o listener quando o componente é desmontado
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Função para verificar se o botão deve ser visível
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Função para rolar a página de volta para o topo
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <IconButton 
            className={`Scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`}
            onClick={scrollToTop}
        >
            <KeyboardArrowUpIcon />
        </IconButton>
    );
}

export default ScrollToTopButton;
import './App.css';
import * as React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import MapPage from './pages/MapPage/MapPage';


function App() {
  // Desconbre o ambiente
  const hostname = window.location.hostname;
  const env = hostname.startsWith("dev") ? "dev" : "prd";

  // Descobre o endpoint da API
  const urls = {
      dev: "https://a3g50dmweb.execute-api.us-east-1.amazonaws.com/dev",
      prd: "https://dbhedm0cub.execute-api.us-east-1.amazonaws.com/prd"
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage env={env} urls={urls} />}></Route>
        <Route path="/map" element={<MapPage env={env} urls={urls} />}></Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
